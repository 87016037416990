import React from 'react'
import { observer } from 'mobx-react'
import graphProps from './data/durationAccuracy.json'
import AnimatedPieCanvas from './AnimatedPieCanvas'

const debug = require('debug')('treks:metrics:thrash')

export default observer(class DurationAccuracyGraph extends React.Component {

  static defaultProps = {
    width: '100%',
    height: '100vh',
    ...graphProps
  }

  render() {
    const { width, height, ...graphProps } = this.props
    debug('render', { width, height, graphProps })
    return (
      <div className="metrics-graph"  style={{ width, height }}>
        <AnimatedPieCanvas
          colors={({ color }) => (color)}
          legends={[]}
          {...graphProps}
        />
      </div>
    )
  }
})
