import qs from 'qs'

export default class UrlQuery {

  constructor(url) {
    this.url = (url instanceof URL || url instanceof Location) ? url : new URL(url)
  }

  getQueryFromUrl() {
    return qs.parse(this.url.search.split('?').pop())
  }

  getPropsFromUrl() {
    try {
      const query = this.getQueryFromUrl()
      if (query.data) {
        query.data = JSON.parse(query.data)
      }
      return query
    } catch(error) {
      throw new Error('The props parameter must be valid JSON')
    }
  }
}