import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import AnimatedPieCanvas from './Components/Metrics/Thrash/AnimatedPieCanvas'
import ThrashGraph from './Components/Metrics/Thrash/ThrashGraph'
import DurationAccuracyGraph from './Components/Metrics/Thrash/DurationAccuracyGraph';
import UrlQuery from './Modules/UrlQuery';

const debug = require('debug')('treks:metrics:app')

function DynamicPieChart({ width, height, data }) {
  debug('render DynamicPieChart', { width, height, data })
  return (
    <div style={{ width, height }}>
      <AnimatedPieCanvas {...data} />
    </div>
  )
}

export default class App extends React.Component {

  url = new UrlQuery(window.location)

  static renderTo(el, props = {}) {
    if (typeof el === 'string') {
      el = document.querySelector(el)
    }
    ReactDOM.render(<App {...props} />, el);
  }

  render() {
    const { props } = this
    const PredefinedGraph = (props.graph === 'thrash' ? ThrashGraph : DurationAccuracyGraph)
    const Component = props.data ? DynamicPieChart : PredefinedGraph
    
    debug('render', { Component, props })
    return (
      <Component {...props} />
    );
  }
}
