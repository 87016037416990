import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UrlQuery from './Modules/UrlQuery';

window.React = React
window.ReactDOM = ReactDOM
window.TreksMetricsApp = App

const url = new UrlQuery(window.location)
const props = url.getPropsFromUrl()

if ('renderGraph' in url.getQueryFromUrl()) {
  ReactDOM.render(<App {...props} />, document.getElementById('root'));
}