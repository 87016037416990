export const data = [
  {
    "id": "stylus",
    "label": "stylus",
    "value": 200,
    "color": "hsl(85, 70%, 50%)"
  },
  {
    "id": "php",
    "label": "php",
    "value": 312,
    "color": "hsl(328, 70%, 50%)"
  },
  {
    "id": "hack",
    "label": "hack",
    "value": 206,
    "color": "hsl(155, 70%, 50%)"
  },
  {
    "id": "lisp",
    "label": "lisp",
    "value": 84,
    "color": "hsl(315, 70%, 50%)"
  },
  {
    "id": "erlang",
    "label": "erlang",
    "value": 535,
    "color": "hsl(61, 70%, 50%)"
  },
  {
    "id": "sass",
    "label": "sass",
    "value": 301,
    "color": "hsl(322, 70%, 50%)"
  },
  {
    "id": "css",
    "label": "css",
    "value": 374,
    "color": "hsl(153, 70%, 50%)"
  },
  {
    "id": "javascript",
    "label": "javascript",
    "value": 428,
    "color": "hsl(233, 70%, 50%)"
  },
  {
    "id": "haskell",
    "label": "haskell",
    "value": 267,
    "color": "hsl(197, 70%, 50%)"
  },
  {
    "id": "rust",
    "label": "rust",
    "value": 312,
    "color": "hsl(183, 70%, 50%)"
  },
  {
    "id": "go",
    "label": "go",
    "value": 300,
    "color": "hsl(141, 70%, 50%)"
  },
  {
    "id": "scala",
    "label": "scala",
    "value": 238,
    "color": "hsl(93, 70%, 50%)"
  },
  {
    "id": "c",
    "label": "c",
    "value": 252,
    "color": "hsl(337, 70%, 50%)"
  },
  {
    "id": "ruby",
    "label": "ruby",
    "value": 341,
    "color": "hsl(23, 70%, 50%)"
  },
  {
    "id": "elixir",
    "label": "elixir",
    "value": 229,
    "color": "hsl(321, 70%, 50%)"
  },
  {
    "id": "python",
    "label": "python",
    "value": 114,
    "color": "hsl(34, 70%, 50%)"
  },
  {
    "id": "java",
    "label": "java",
    "value": 429,
    "color": "hsl(282, 70%, 50%)"
  },
  {
    "id": "make",
    "label": "make",
    "value": 46,
    "color": "hsl(147, 70%, 50%)"
  }
]

export default data
