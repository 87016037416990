import React from 'react'
import { observer } from 'mobx-react'
import data from './data'
import PieCanvas from './PieCanvas'
import { observable } from 'mobx';
import anime from 'animejs/lib/anime.es.js';

const debug = require('debug')('treks:metrics:AnimatedPieGraph')

export default observer(class AnimatedPieGraph extends React.Component {

  static defaultProps = {
    data
  }

  store = observable({
    startAngle: 0,
    endAngle: 0,
    radialLabelsLinkDiagonalLength: 0,
    radialLabelsLinkHorizontalLength: 0,
    enableRadialLabels: false
  })

  componentDidMount() {
    const { radialLabelsLinkDiagonalLength, radialLabelsLinkHorizontalLength } = this.props
    anime({
      targets: this.store,
      startAngle: -360,
      endAngle: 0,
      round: 1,
      easing: 'easeInQuad',
      update: () => {
        debug('anime update', this.store)
      },
      complete: () => {
        this.store.enableRadialLabels = true
        anime({
          targets: this.store,
          radialLabelsLinkDiagonalLength: radialLabelsLinkDiagonalLength || 15,
          radialLabelsLinkHorizontalLength: radialLabelsLinkHorizontalLength || 30,
        })
      }
    })
  }

  render() {
    debug('render', { props: this.props, store: this.store })
    return (
      <PieCanvas
        {...this.props}
        {...this.store}
      />
    )
  }
})
