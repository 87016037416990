import React from 'react'
import { ResponsivePie } from '@nivo/pie'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsivePie = props => (
    <ResponsivePie
        margin={{ top: 40, right: 200, bottom: 40, left: 100 }}
        pixelRatio={2}
        startAngle={0}
        endAngle={0}
        innerRadius={0.5}
        padAngle={1}
        cornerRadius={3}
        fit={false}
        colors={{ scheme: 'category10' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', '0.5' ] ] }}
        radialLabel={({ label, id }) => label || id }
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={2}
        radialLabelsTextColor="#606060"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={10}
        radialLabelsLinkHorizontalLength={10}
        radialLabelsLinkStrokeWidth={2}
        radialLabelsLinkColor={{ from: 'color' }}
        enableSlicesLabels={false}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        sortByValue={true}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    type: 'dots'
                },
                id: 'dots'
            },
            {
                match: {
                    type: 'lines'
                },
                id: 'lines'
            },
            {
                match: {
                    type: 'large'
                },
                id: 'large'
            }
        ]}
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                translateX: 140,
                itemWidth: 60,
                itemHeight: 14,
                itemsSpacing: 2,
                symbolSize: 14,
                symbolShape: 'circle'
            }
        ]}
        theme={{
            labels: {
                text: {
                    fontSize: 18
                }
            }
        }}
        {...props}
    />
)

export default MyResponsivePie